import React, { useContext } from "react";
import PropsType from "prop-types";
import Icon from "../components/icon.tsx";
import * as b from "./bootstrap.module.css";
import Translate from "./translate";
import { ComparisionContext } from "./comparision-context";

const ProductCheckboxComparison = ({ item, settings }) => {
  const { toggleComparisionItem, hasComparisionItem } = useContext(ComparisionContext);
   
  const key = 
    item.productType === 'firm' ? `firms${settings.subtypes['firm']}`
    : item.productType === 'credits' ? `credits${settings.subtypes['credits']}`
    : item.productType;

  const isActive =  hasComparisionItem(key, item.mysqlId);

  return (
    <button
      className={[ "btn-hidden",b.textUppercase,b.dFlex,b.alignItemsCenter,b.justifyContentCenter,b.w100,"firm-row-no-comparison-text",].join(" ")} 
      onClick={() => toggleComparisionItem(key, item.mysqlId)}
    >
      {isActive && <Icon size={20} icon="Checkbox-marked" className={[b.mr1, "icon-width"].join(" ")} />}
      {!isActive && <Icon size={20} icon="Checkbox-empty" className={[b.mr1, "icon-width"].join(" ")} />}
      <Translate tKey="Compare" />
    </button>
  );
};

ProductCheckboxComparison.propsTypes = {
  item: PropsType.object.isRequired,
};

export default ProductCheckboxComparison;
