import resolveProductUrl from './urls/resolve-product-url';

const calcInitState = (item) => {
  if (!item.mysqlImage || (!item.specific_url && !item.prefix_url)) {
    return { visible: 0 }
  }

  return {
    visible: 1,
    formVisible: item?.form_visible ?? 1,
    name: item.name,
    was_selected: item.likes,
    url: resolveProductUrl(item),
    image: item.mysqlImage,
    rating: item.rating / 20,
    prefix_url: item.prefix_url,
    productType: item.productType,
    points_to_note: item.points_to_note,
    labels: (item.labels || item.labels_custom) 
      ? [
        ...item.labels_custom.split('\n'),
        ...item.labels.split(' ').map((key) => ({"free": "Pierwsza darmowa pożyczka", "new": "Nowość"}[key])),
      ].filter((label) => label && label.length < 30) 
      : [],
  }
};

const currency = (amount) => {
  if (/\/|\s/.test(amount)) {
    return `${amount} zł`;
  }

  return parseFloat(amount).toFixed(2) + ' zł';
};

const productData = (item) => {
  const newState = calcInitState(item);

  switch (item.productType) {
    case 'firm':
      if (item.productSubtypes.includes(2) || item.productSubtypes.includes(3)) {
        const amount = item.costs?.amount ?? null;
        const time = item.costs?.time ?? null;
        const installment = item.costs?.installment ?? null;
        const cost = installment != null && time != null 
          ? (installment ?? 0) * (time ?? 0) 
          : null;

        newState.content = [
          ["Kwota", amount ? currency(amount) : "-"],
          ["Okres", time ? `${time} miesięcy` : "-"],
          ["Rata",  typeof installment === "number" ? currency(installment) : "-"],
          ["RRSO", typeof item.costs.apr === 'string' || typeof item.costs.apr === 'number' ? `${item.costs?.apr} %` : "-"],
          ["Do oddania", typeof cost === "number" ? currency(cost) : "-"],
        ];
      }
      else {
        newState.content = [
          ['Kwota', item.costs?.amount ? currency(item.costs.amount) : '-'],
          ['Okres', item.costs?.time ? `${item.costs.time} dni` : '-'],
          ['Koszt', typeof item.costs?.cost === "number" ? currency(item.costs.cost) : '-'],
          ['RRSO', typeof item.costs.apr === 'string' ||  typeof item.costs.apr === 'number' ? `${item.costs.apr} %` : '-'],
          ['Do oddania', typeof item.costs?.amount === "number" && typeof item.costs?.cost === "number" ? currency(item.costs.amount + item.costs.cost) : '-'],
        ]
      }
      break
    case 'accounts':
      if (item.type === 1) {
        newState.content = [
          ['Opłata za prowadzenie', item.account_fees ? currency(item.account_fees) : '-'],
          ['Bankomaty', item.payment_bank ? currency(item.payment_bank) : '-'],
          ['Opłata za kartę', item.card_fees ? currency(item.card_fees) : '-'],
          ['Koszt prowadzenia rachunku', item.interest ? currency(item.interest) : '-'],
          ['Płatności mobilne', item.mobile_payment ? 'tak' : 'nie'],
        ]
      } else if (item.type === 2) {
        newState.content = [
          ['Oprocentowanie', item.interest ? `${item.interest} %` : '-'],
          ['Kwota', item.amount_max || '-'],
          ['Konto osobiste', `${item.personal_account > 0  ? 'Tak' : 'Nie'}`],
          ['Koszt prowadzenia', item.account_fees ? currency(item.account_fees) : '-'],
        ]
      } else if (item.type === 4 || item.type === 7) {
        newState.content = [
          ['Opłata za prowadzenie', item.account_fees ? currency(item.account_fees) : '-'],
          ['Opłata za przelew', item.transfer_online ? currency(item.transfer_online) : '-'],
          ['Karta', item.card_fees ? currency(item.card_fees) : '-'],
          ['Bankomaty', item.payment_bank ? currency(item.payment_bank) : '-'],
        ];
      } else if (item.type === 5) {
        newState.content = [
          ['Dostęp do walut', item.available_currencies || '-'],
          ['Własny kantor', item.own_exchange_office ? 'tak' : 'nie'],
          ['Opłata za otwarcie', item.opening_fee ? currency(item.opening_fee) : '-'],
          ['Opłata za kartę', item.card_fees ? currency(item.card_fees) : '-'],
          ['Darmowe wypłaty', item.transfer_bank ? 'tak' : 'nie'],
        ];
      } else if (item.type === 6) {
        newState.content = [
          ['Oprocentowanie', item.interest || '-'],
          ['Okres', item.commitment_period || '-'],
          ['Kwota', item.amount_max || '-'],
        ];
      }
      break
    case 'credits':
      newState.content = [
        ['Oprocentowanie', `${item.percent || 0} %`],
        ['RRSO', item.aprc >= 0 ? `${item.aprc} %` : '-'],
        ['Prowizja', item.fee_min < item.fee_max ? `${item.fee_min}/${item.fee_max} %` : `${item.fee_min || 0} %`],
        ['Rata', item?.calculations?.rate ? currency(item.calculations.rate) : '-'],
        ['Kwota do spłaty', item?.calculations?.cost ? currency(item.calculations.cost) : '-'],
      ]
      break
    case 'cards':
      if (item.type === 1) {
      newState.content = [
        ['Limit kredytowy', item.limit_max ? currency(item.limit_max) : '-'],
        ['Okres bezodsetkowy', item.free_period ? `${item.free_period} dni` : '-'],
        ['Opłata za wydanie', item.card_fee ? currency(item.card_fee) : '-'],
        ['Oprocentowanie', `${item.interest || 0} %` ],
        ['Operator karty', item?.card_operator ? item?.card_operator : '-'],
      ];
    } else {
      newState.content = [
        ['Limit kredytowy', item.limit_max ? currency(item.limit_max) : '-'],
        ['RRSO', item.aprc ? `${item.aprc} %` : '-'],
        ['Opłata za wydanie', item.card_fee ? currency(item.card_fee) : '-'],
        ['Operator karty', item?.card_operator ? item?.card_operator : '-'],
      ];
    }
      break
    default:
      return newState
  }
  return { ...newState, isLoaded: true }
}


export default productData