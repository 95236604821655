import React from "react";
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Breadcrumbs from "../components/breadcrumbs";
import CalculatorShortcode from "../components/shortcodes/calculator-shortcode";
import BlogContent from "../components/blog-content";
import Layout from "../components/layout";
import Posts from '../components/posts';

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} title={`${props?.data?.mysqlBlog?.title} | akredo.pl`} description={props?.data?.mysqlBlog?.intro}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/blog/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const BlogCalculator = ({ 
  data: { mysqlBlog, allMysqlBlog }, 
  pageContext: { breadcrumbs },
  location: { pathname }, 
}) => {
  const {
    title,
    intro,
    content,
  } = mysqlBlog;

  return (
    <Layout pathname={pathname}>
      <article className={["blog-page", b.pb3, b.ptLg4, b.mbLg5].join(' ')}>
        <div className={[b.container, "breadcrumbs-mobile-products", b.pb3,"scroll-inner",b.my0, b.mtLg4].join(" ")}>
          <Breadcrumbs theme="white" items={breadcrumbs}/>
        </div>
        <div className={b.container}>
          <div className={["blog-content", b.row, b.my5, b.mbLg4, b.pt0, 'blueToGray', 'dark-li'].join(" ")}>
            <div className={`${b.colXl12} dark-theme-listing`}>
              <h1 className={["big-title", b.mb3].join(" ")}>{title}</h1>
              <p className={["font-size-22","blueToWhite"].join(' ')}>{intro}</p>
              
              {content && (
                <CalculatorShortcode value={content}>
                  {({ startHalf, calculator, endHalf }) => (<>
                    <BlogContent content={startHalf} />
                    {calculator && <div className="calculator-wrapper">{calculator}</div>}
                    {endHalf && <BlogContent content={endHalf} />}
                  </>)}
                </CalculatorShortcode>
              )}

            </div>
          </div>

          {allMysqlBlog.nodes.length > 0 && <aside className={[b.mt5, b.pbLg5].join(" ")}>
            <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>Podobne treści {mysqlBlog.title}</h2>
            <Posts items={allMysqlBlog.nodes} />
          </aside>}
        </div>
      </article>
    </Layout>
  );
};

export default BlogCalculator;

export const query = graphql`
  query BlogCalculator($mysqlId: Int!, $term_id: Int!, $withoutUrls: [String]) {
    mysqlBlog(status: { eq: "publish" }, mysqlId: { eq: $mysqlId }) {
      title
      intro
      content
    },

    allMysqlBlog(filter: {url: {nin: $withoutUrls}, mysqlId: {ne: $mysqlId}, term_id: {eq: $term_id}, is_category: {eq: null}, description: {ne: null}, status: {eq: "publish"}}) {
      nodes {
        ...PostData
      }
    }
  }
`;
